import { createBrowserRouter } from "react-router-dom";
import ItemList from "../pages/ItemList";
import ClientList from "../pages/ClientList";
import AdminList from "../pages/AdminList";
import ActivityLog from "../pages/ActivityLog";
import LinkShortener from "../pages/LinkShortener";
import Main from "../pages/Main";
import LoginPage from "../pages/LoginPage";
import { redirect } from "react-router-dom";

// let baseURLClient = "http://localhost:3000/"
let baseURLClient = "https://admin.samkalibrasi.com/"

const protectRoute = () => {
	if (!localStorage.access_token) {
		throw redirect("/login");
	} else {
		return null;
	}
};
// const baseUrl = "http://localhost:4000/";
const baseUrl = "https://api.samkalibrasi.com:4001/"
const router = createBrowserRouter([
	{
		path: "/",
		element: <Main />,
		children: [
			{
				path: "",
				element: <ItemList />,
				loader: protectRoute,
				loader: () => {
					if (!localStorage.access_token) {
						throw redirect("/login");
					} else {
						return null;
					}
				},
			},
			{
				path: "clients",
				element: <ClientList />,
				loader: () => {
					if (!localStorage.access_token) {
						throw redirect("/login");
					} else {
						if (localStorage.role === "customer") {
							throw redirect("");
						} else {
							return null;
						}
					}
				},
			},
			{
				path: "admins",
				element: <AdminList />,
				loader: () => {
					if (!localStorage.access_token) {
						throw redirect("/login");
					} else {
						if (localStorage.role !== "director") {
							throw redirect("");
						} else {
							return null;
						}
					}
				},
			},
			{
				path: "logs",
				element: <ActivityLog />,
				loader: () => {
					if (!localStorage.access_token) {
						throw redirect("/login");
					} else {
						if (localStorage.role !== "director") {
							throw redirect("");
						} else {
							return null;
						}
					}
				},
			},
			{
				path: "link-shortener",
				element: <LinkShortener />,
				loader: () => {
					if (!localStorage.access_token) {
						throw redirect("/login");
					} else {
						if (localStorage.role !== "director") {
							throw redirect("");
						} else {
							return null;
						}
					}
				},
			},
			{
				path: ":shortUrl",
				element: null, // No UI needed for redirect
				loader: async ({ params }) => {
					const shortUrl = params.shortUrl;
					try {
						// Call your API to resolve the short URL to the original URL
						const response = await fetch(baseUrl + `links/redirect`, {
							method: "POST",
							body: JSON.stringify({ shortLink: baseURLClient + shortUrl }),
							headers: {
								"Content-Type": "application/json",
								// access_token: localStorage.access_token,
							},
						});
						if (!response.ok) {
							console.log(response);
							throw new Error("Invalid short URL");
						}
						const data = await response.json();
						console.log(data.url);
						// Redirect to the original URL
						// throw redirect("https://facebook.com");
						// window.location.href = data.url;
						return redirect(data.url);
						// throw redirect(data.url)
						// return null;
						// window.location.href = "https://facebook.com";
					} catch (error) {
						console.error(error);
						throw redirect("/"); // Redirect to home on failure
					}
				},
			},
		],
	},
	{
		path: "/login",
		element: <LoginPage />,
		loader: () => {
			if (localStorage.access_token) {
				throw redirect("/");
			} else {
				return null;
			}
		},
	},
]);

export default router;
