import Sidebar from "../components/Sidebar";
import Papa from "papaparse";
import { fetchActivities, fetchActivityLogs } from "../store/actions/actionCreator";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import ActivityLogFilterBox from "../components/ActivityLogFilterBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
const { parseStringToDate } = require("../store/helpers/helpers.js");

export default function ActivityLog() {
	const isShrunkDesktop = useMediaQuery({ query: "(max-width: 1263px" });
	const isFullDesktop = useMediaQuery({ query: "(min-width: 1264px" });
	const isMobile = useMediaQuery({ query: "(max-width: 770px" });
	const [dateSort, setDateSort] = useState("");
	const [currentPage, setCurrentPage] = useState(0);

	const dispatch = useDispatch();

	const activityLogs = useSelector((state) => {
		return state.activityLogs.activityLogs;
	});

	// const activities = useSelector((state) => {
	// 	return state.activities.activities;
	// });

	useEffect(() => {
		dispatch(fetchActivityLogs());
	}, []);

	let marginLeftComponent;
	if (isShrunkDesktop) {
		marginLeftComponent = "100px";
	} else {
		marginLeftComponent = "250px";
	}

	//sorting

	function sorterAscDate(a, b) {
		const dateA = parseStringToDate(a.time);
		const dateB = parseStringToDate(b.time);
		console.log(a);
		if (dateA === null) return 1;
		if (dateB === null) return -1;
		return dateA - dateB;
	}

	function sorterDescDate(a, b) {
		const dateA = parseStringToDate(a.time);
		const dateB = parseStringToDate(b.time);
		if (dateA === null) return 1;
		if (dateB === null) return -1;
		return dateB - dateA;
	}

	if (dateSort === "ASC") {
		activityLogs.sort(sorterAscDate);
	} else if (dateSort === "DESC") {
		activityLogs.sort(sorterDescDate);
	}

	//CSV export

	function convertToCSV(data) {
		const keysToRemove = ["Activity", "createdAt", "updatedAt"];
		const newData = JSON.parse(JSON.stringify(data));
		newData.forEach((obj) => {
			keysToRemove.forEach((key) => {
				delete obj[key];
			});
		});
		const csv = Papa.unparse(newData);
		return csv;
	}

	function handleExportToCSV() {
		const csv = convertToCSV(subset);
		const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
		const link = document.createElement("a");
		if (link.download !== undefined) {
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", "activity-log.csv");
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	//pagination

	const handlePageChange = (selectedPage) => {
		setCurrentPage(selectedPage.selected);
		dispatch(fetchActivityLogs({ page: currentPage }));
	};

	const logsCount = useSelector((state) => {
		return state.activityLogs.count;
	});

	const itemsPerPage = 25;

	const totalPages = Math.ceil(logsCount / itemsPerPage);
	const startIndex = currentPage * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const subset = activityLogs.slice(startIndex, endIndex);

	return (
		<div className="flex w-screen h-screen">
			<Sidebar />
			<div
				className="grow px-10 py-10 flex flex-col items-start"
				style={{ marginLeft: marginLeftComponent }}
			>
				<div className="w-full border-b-2 border-themeGreen">
					<h1 className="text-2xl font-bold tracking-wide text-left">
						Log Aktivitas
					</h1>
				</div>
				<div className="flex w-full items-center gap-12">
					<ActivityLogFilterBox />
					<h1
						className="text-sm text-blue-600 underline cursor-pointer float-right"
						onClick={handleExportToCSV}
					>
						Download log (CSV)
					</h1>
				</div>

				{activityLogs.length > 0 ? (
					<div className="w-full rounded-lg mt-12 px-4 py-2">
						<table className="w-full">
							<thead className="">
								<tr className="text-left text-sm border-b bg-themeGreen text-white py-2">
									<th className="px-2 py-1">Tipe Aktivitas</th>
									{/* <th className="px-2 py-1">Tipe User</th> */}
									<th className="px-2 py-1">User ID</th>
									<th className="px-2 py-1">
										<div className="flex items-center gap-2">
											<p>Waktu</p>
											<input
												type="checkbox"
												id="hacky-input-date"
												className=""
											/>
											<label
												for="hacky-input-date"
												className="rotate cursor-pointer"
											>
												<FontAwesomeIcon
													icon={faCaretDown}
													onClick={() => {
														if (dateSort === "ASC") {
															setDateSort("DESC");
														} else {
															setDateSort("ASC");
														}
													}}
												/>
											</label>
										</div>
									</th>
									<th className="px-2 py-1">Detail</th>
									<th className="px-2 py-1"></th>
								</tr>
							</thead>
							{activityLogs && (
								<tbody>
									{subset.map((log) => (
										<tr
											className="text-left text-sm border-b"
											key={log.id}
										>
											<td className="px-2 py-3 font-bold">
												{log.Activity?.name}
											</td>
											{/* <td className="px-2 py-3 font-bold">
												{" "}
												{log.UserId[0] == "A" ? "Admin" : "Customer"}
											</td> */}

											<td className="px-2 py-3 font-bold">{log.UserId}</td>
											<td className="px-2 py-3">{log.time}</td>
											<td className="px-2 py-3">{log.detail}</td>
										</tr>
									))}
								</tbody>
							)}
						</table>
						<ReactPaginate
								className="flex w-full justify-center gap-10 my-8 text-xs"
								previousLabel={"<<"}
								nextLabel={">>"}
								pageCount={totalPages}
								onPageChange={handlePageChange}
								forcePage={currentPage}
								pageRangeDisplayed={3} // Change the number to control how many page numbers are displayed
								marginPagesDisplayed={1}
							/>
					</div>
				) : (
					<div className="w-full h-full mx-auto items-center justify-center flex">
						<h1 className="text-center py-30">
							Tidak ada log aktivitas yang dapat ditampilkan
						</h1>
					</div>
				)}
			</div>
		</div>
	);
}
