import Sidebar from "../components/Sidebar";
import Papa from "papaparse";
import { addLink } from "../store/actions/actionCreator";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

export default function LinkShortener() {
	// const baseUrl = "http://localhost:4000/";
	const baseUrl = "https://api.samkalibrasi.com:4001/"
	const isShrunkDesktop = useMediaQuery({ query: "(max-width: 1263px" });
	const isFullDesktop = useMediaQuery({ query: "(min-width: 1264px" });
	const isMobile = useMediaQuery({ query: "(max-width: 770px" });
	const [dateSort, setDateSort] = useState("");
	const [currentPage, setCurrentPage] = useState(0);

	const dispatch = useDispatch();

	const activityLogs = useSelector((state) => {
		return state.activityLogs.activityLogs;
	});

	// const [link, setLink] = useState();
	const [apiResult, setApiResult] = useState(null);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const link = e.target.elements.link.value;
		console.log(link.length);
		if (link.length == 0) {
			toast.error("Link cannot be empty.");
			return;
		}

		try {
			const response = await fetch(baseUrl + "links/shortLinks", {
				method: "POST",
				body: JSON.stringify({ url: link }),
				headers: {
					"Content-Type": "application/json",
					access_token: localStorage.access_token,
				},
			});
			const data = await response.json();

			if (response.ok) {
				console.log(data);
				setApiResult(data.shortLink.url);
			} else {
				console.log(response);
				setApiResult(`Error: ${data.message}`);
			}
		} catch (error) {
			console.log(error);
			setApiResult("Failed to connect to the API.");
		}
	};

	let marginLeftComponent;
	if (isShrunkDesktop) {
		marginLeftComponent = "100px";
	} else {
		marginLeftComponent = "250px";
	}

	return (
		<div className="flex w-screen h-screen">
			<Sidebar />
			<div
				className="grow flex flex-col items-center justify-center"
				style={{ marginLeft: marginLeftComponent }}
			>
				<p className="mb-4 font-bold text-xl">LINK SHORTENER PAGE</p>
				<form
					className="flex items-center"
					onSubmit={handleSubmit}
				>
					<input
						type="text"
						name="link"
						placeholder="Copy the URL directly from address box"
						className="px-4 py-2 border rounded h-10"
					/>
					<button
						type="submit"
						className="ml-2 px-6 h-10 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center justify-center"
					>
						Shorten
					</button>
				</form>

				{apiResult && (
					<p className="mt-4">
						Shortened Link:{" "}
						<a
							href={apiResult}
							target="_blank"
							rel="noopener noreferrer"
							className="text-blue-500 underline"
						>
							{apiResult}
						</a>
					</p>
				)}
			</div>
		</div>
	);
}
